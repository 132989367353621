const formatDate = (timestamp: string | undefined) => {
  const date = timestamp?.split('T')

  if (!date) return

  const [year, month, day] = date[0].split('-')

  return `${day}.${month}.${year}`
}

export default formatDate
